<template>
  <div class="aaccountNumberDescription">
    <div class="centent">
      <h1>如何获取电力户号?</h1>
      <h5>户号是什么?户号是客户编号的简称,是每个用电客户独有的用电档案编号,也是用户办理用电业务的凭证。一般为10位数。</h5>
      <h5>⭐方法一：在电力公司提供的电费发票上查询。</h5>
      <h5>⭐方法二：在银行等代售电网点提供的发票上查询。</h5>
      <h5>⭐方法三：在您开户时给您的居民用户用电登记表或购电证中查询。</h5>
      <h5>⭐方法四：通过在电力机构预留的身份证号、地址、姓名等信息，拨打<a href="tel:10086">95598</a>进行人工客服查询对应的户号。</h5>
      <h5>⭐方法五：查看家中的电表编号(电表条码)，拨打<a href="tel:10086">95598</a>进行人工客服查询对应的户号。</h5>
      <h5></h5>
      <h1>如何获取燃气户号?</h1>
      <h5>户号是什么?户号是客户编号的简称,是每个燃气客户独有的用燃气档案编号,也是用户办理用燃气业务的凭证。</h5>
      <h5>⭐方法一：先仔细查看一下燃气卡，一般燃气卡上标有缴费户号；</h5>
      <h5>⭐方法二：带着燃气卡去当地的燃气公司进行查询；</h5>
      <h5>⭐方法三：可以查看之前交燃气费的单子，单子上面也可以看见天然气用户编号；</h5>
      <h5>⭐方法四：也可以通过支付宝上充值中心的“生活缴费”服务查询自己家的天燃气用户号，同时也可以进行缴费、余额查询等多项服务。</h5>
      <h5></h5>
      <h5 style="color: red;">注：以上方式均仅供参考使用，具体按照当地电力公司要求方式查询。</h5>
    </div>

  </div>
</template>
<script>
export default {
  name: 'AaccountNumberDescription'
}
</script>
<style scoped>
.centent{
  margin: 10px;
}
</style>
